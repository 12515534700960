<template>
  <base-view title="Saldos por partidas presupuestales">
    <div class="container-fluid mt-4">
      <div class="card">
          <div class="card-body">
              <PartidaPresupuestal/>
          </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import PartidaPresupuestal from '@/components/Saldos/PartidaPresupuestal'

export default {
  name: 'PartidaPresupuestalView',

  components: {
    PartidaPresupuestal
  }
}
</script>
