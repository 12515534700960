<template>
    <div>
        <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" modulo="Reportes por saldos"/>

        <b-overlay :show="loading">
          <template #overlay>
            <loading />
          </template>

          <x-select-partida-presupuestal
            v-if="periodo_fiscal_id"
            :periodo="periodo_fiscal_id"
            class="mb-3"
            @input="onChangeUnidad"
          />

          <b-table
            :fields="fields"
            :items="getSaldos"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell()="data">
              <div v-if="getKeyName(data) != 'clave' && getKeyName(data) != 'nombre'">
                {{ $formatNumber(data.value) }}
              </div>
              <div v-else>
                {{ data.value }}
              </div>
            </template>
          </b-table>
        </b-overlay>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PartidaPresupuestal',

  data () {
    return {
      reset: false,

      loading: false,

      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'enero', label: 'Enero' },
        { key: 'febrero', label: 'Febrero' },
        { key: 'marzo', label: 'Marzo' },
        { key: 'abril', label: 'Abril' },
        { key: 'mayo', label: 'Mayo' },
        { key: 'junio', label: 'Junio' },
        { key: 'julio', label: 'Julio' },
        { key: 'agosto', label: 'Agosto' },
        { key: 'septiembre', label: 'Septiembre' },
        { key: 'octubre', label: 'Octubre' },
        { key: 'noviembre', label: 'Noviembre' },
        { key: 'diciembre', label: 'Diciembre' }
      ],

      periodo_fiscal_id: null
    }
  },

  computed: {
    ...mapGetters('saldoModule', [
      'getSaldos'
    ]),

    ...mapGetters('fiscalPeriodsModule', [
      'getAllFiscalPeriods'
    ])
  },

  methods: {
    async onChangePeriodoFiscal (periodo_fiscal_id) {
      this.loading = true
      const { error, message } = await this.$store.dispatch('saldoModule/getSaldosPartidasPresupuestales', periodo_fiscal_id)
      this.loading = false

      this.periodo_fiscal_id = periodo_fiscal_id

      if (error) return this.$notify({ error, message }, 'Error')
    },

    async onChangeUnidad (partida_id) {
      if (!partida_id && this.periodo_fiscal_id) {
        this.onChangePeriodoFiscal(this.periodo_fiscal_id)
        return
      }

      if (!this.periodo_fiscal_id) return

      this.loading = true

      const { error, message } = await this.$store.dispatch('saldoModule/getSaldosPorPartida', {
        periodo_fiscal_id: this.periodo_fiscal_id,
        partida_id: partida_id
      })

      this.loading = false

      if (error) return this.$notify({ error, message }, 'Error')
    },

    getKeyName (data) {
      return data.field.key
    }
  }
}
</script>
